<template>
  <div class="profile-tab">
    <div class="_about-section row align-items-top">
      <div class="col-md-2">
        <div class="thumbnail">
          <img v-if="profile.image != null" :src="profile.image" alt="..." />
          <img v-else src="../assets/thumb.png" alt="..." />
          <a
            class="edit-cta"
            href="javascript:void(0);"
            @click="pickProfileImage"
            ><i class="fas fa-pen"></i
          ></a>
          <input
            type="file"
            id="image"
            name="image"
            style="display: none"
            ref="imageUploader"
            accept="image/jpeg,image/jpg,image/png"
            @change="onImageSelected"
          />
        </div>
      </div>
      <div class="col-md-8">
        <h6>{{ studentName }}</h6>
        <p>
          <i class="fas fa-id-badge" title="VBLive Admission Number"></i>
          {{ profile.student_details.admission_number }}
        </p>
        <p>
          <i class="fas fa-at" title="Email Id"></i> {{ profile.email }}
          <span
            v-if="profile.email_verified_at"
            title="Verified Email"
            class="fa-stack fa-xs"
          >
            <i class="fas fa-certificate fa-stack-2x" style="color: Green"></i>
            <i class="fas fa-check fa-stack-1x fa-inverse"></i>
          </span>
        </p>
        <p>
          <i
            :class="profile.phone ? 'fas fa-mobile-alt' : 'fas fa-phone-slash'"
            :title="
              profile.phone ? 'Contact Number' : 'No Contact Number Added'
            "
          ></i>
          {{ profile.phone_number_country_code }} {{ profile.phone }}
          <span
            v-if="profile.phone_verified_at"
            title="Verified Number"
            class="fa-stack fa-xs"
          >
            <i class="fas fa-certificate fa-stack-2x" style="color: Green"></i>
            <i class="fas fa-check fa-stack-1x fa-inverse"></i>
          </span>
        </p>
      </div>
      <!-- <div class="col-md-2">
        <a href="#" class="small-cta">Edit</a>
      </div> -->
    </div>

    <div class="info-section">
      <div class="_section-head justify-content-between">
        <h6>Personal information</h6>
        <div>
          <a
            href="javascript:void(0);"
            v-if="!isFormActive"
            @click="isFormActive = !isFormActive"
            class="small-cta"
          >
            Edit
          </a>
          <a
            href="javascript:void(0);"
            v-if="isFormActive"
            @click="revokeChanges"
            class="small-cta"
          >
            Cancel
          </a>
          <a
            href="javascript:void(0);"
            v-if="isFormActive"
            @click="updateProfile"
            class="small-cta"
            ref="profileContainer"
          >
            Save
          </a>
        </div>
      </div>
      <div class="_section-body px-0">
        <form>
          <div class="form-group row">
            <div class="col-md-6">
              <label>First Name <span v-show="isFormActive">*</span></label>
              <p class="field-content" v-if="!isFormActive">
                {{ profile.first_name }}
              </p>
              <input
                v-else
                type="text"
                class="form-control"
                v-model="profile.first_name"
              />
              <div
                class="input-errors"
                v-for="error of v$.profile.first_name.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="col-md-6">
              <label>Last Name <span v-show="isFormActive">*</span></label>
              <p class="field-content" v-if="!isFormActive">
                {{ profile.last_name }}
              </p>
              <input
                v-else
                type="text"
                class="form-control"
                v-model="profile.last_name"
              />
              <div
                class="input-errors"
                v-for="error of v$.profile.last_name.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="col-md-12">
              <label>Bio</label>
              <!-- Note:- Don't use v-html to show user given text fields to prevent XSS Attacks -->
              <p class="field-content" v-if="!isFormActive">
                {{ profile.bio }}
              </p>
              <textarea
                v-else
                v-model.trim="profile.bio"
                class="form-control"
                rows="3"
                placeholder="Enter bio"
              ></textarea>
              <div
                class="input-errors"
                v-for="error of v$.profile.bio.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-6">
              <label>DOB </label>
              <p class="field-content" v-if="!isFormActive">
                {{ profile.student_details.dob }}
              </p>
              <v-date-picker
                v-else
                v-model="profile.student_details.dob"
                :available-dates="{ start: minDate, end: maxDate }"
                :masks="masks"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    class="form-control"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="YYYY-MM-DD"
                    readonly
                  />
                </template>
              </v-date-picker>
            </div>
            <div class="col-md-6">
              <label>Gender <span v-show="isFormActive">*</span></label>
              <p class="field-content" v-if="!isFormActive">
                {{ profile.gender }}
              </p>
              <Multiselect
                v-else
                v-model="profile.gender"
                v-bind="genderOptions"
              />
              <div
                class="input-errors"
                v-for="error of v$.profile.gender.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-6">
              <label>Address </label>
              <!-- Note:- Don't use v-html to show user given text fields to prevent XSS Attacks -->
              <p class="field-content" v-if="!isFormActive">
                {{ profile.student_details.address }}
              </p>
              <textarea
                v-else
                v-model.trim="profile.student_details.address"
                class="form-control"
                rows="3"
                placeholder="Enter address"
              ></textarea>
            </div>
            <div class="col-md-6">
              <label>State </label>
              <p class="field-content" v-if="!isFormActive">
                {{ profile.student_details.state }}
              </p>
              <input
                v-else
                type="text"
                class="form-control"
                v-model="profile.student_details.state"
                placeholder="Enter state"
              />
            </div>
            <div class="col-md-6">
              <label>Country </label>
              <p class="field-content" v-if="!isFormActive">
                <span v-if="profile.country">{{ profile.country.name }}</span>
              </p>
              <Multiselect
                v-else
                v-model="countryOptions.value"
                v-bind="countryOptions"
              />
            </div>
            <div class="col-md-6">
              <label>Zipcode </label>
              <p class="field-content" v-if="!isFormActive">
                {{ profile.student_details.zip_code }}
              </p>
              <input
                v-else
                type="text"
                class="form-control"
                v-model="profile.student_details.zip_code"
                placeholder="Enter zipcode"
              />
            </div>
            <div class="col-md-6">
              <label>Timezone <span v-show="isFormActive">*</span></label>
              <p class="field-content" v-if="!isFormActive">
                {{ profile.timezone }}
              </p>
              <Multiselect
                v-else
                v-model="timezoneOptions.value"
                v-bind="timezoneOptions"
              />
              <div
                class="input-errors"
                v-for="error of v$.timezoneOptions.value.$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-6">
              <label>Parent/Guardian Name</label>
              <p class="field-content" v-if="!isFormActive">
                {{ profile.student_details.parent_name }}
              </p>
              <input
                v-else
                type="text"
                class="form-control"
                v-model="profile.student_details.parent_name"
                placeholder="Enter parent/guardian name"
              />
            </div>
            <div class="col-md-6">
              <label>Parent/Gaurdian Contact No. </label>
              <p class="field-content" v-if="!isFormActive">
                {{ profile.student_details.parent_contact_number }}
              </p>
              <input
                v-else
                type="text"
                class="form-control"
                v-model="profile.student_details.parent_contact_number"
                placeholder="Enter parent/guardian contact number without country code"
              />
              <div
                class="input-errors"
                v-for="error of v$.profile.student_details.parent_contact_number
                  .$errors"
                :key="error.$uid"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-6">
              <label>Advertising Medium</label>
              <p class="field-content" v-if="!isFormActive">
                {{ profile.student_details.advertising_medium }}
              </p>
              <input
                v-else
                type="text"
                class="form-control"
                v-model="profile.student_details.advertising_medium"
                placeholder="You know about us through..."
              />
            </div>
          </div>
          <hr />

          <div class="_section-head">
            <h6>Admission Details</h6>
            <!-- <a href="#" class="small-cta">Edit</a> -->
          </div>
          <div class="_section-body">
            <div class="form-group row">
              <div class="col-md-6">
                <label>School </label>
                <div v-if="!isFormActive">
                  <p
                    class="field-content"
                    v-if="profile.student_details.school"
                  >
                    {{ profile.student_details.school.school_name }}
                  </p>
                </div>
                <Multiselect
                  v-else
                  v-model="schoolOptions.value"
                  v-bind="schoolOptions"
                  @select="checkIfOtherSchool"
                />
              </div>
              <div class="col-md-6">
                <label>Standard <span v-show="isFormActive">*</span></label>
                <div v-if="!isFormActive">
                  <p
                    class="field-content"
                    v-if="profile.student_details.standard"
                  >
                    {{ profile.student_details.standard.standard_title }}
                  </p>
                </div>
                <Multiselect
                  v-else
                  v-model="standardOptions.value"
                  v-bind="standardOptions"
                />
                <div
                  class="input-errors"
                  v-for="error of v$.standardOptions.value.$errors"
                  :key="error.$uid"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <div v-if="isOtherSchool" class="col-md-6">
                <label>School Name <span v-show="isFormActive">*</span></label>
                <div v-if="!isFormActive">
                  <p
                    class="field-content"
                    v-if="profile.student_details.other_school_name"
                  >
                    {{ profile.student_details.other_school_name }}
                  </p>
                </div>
                <input
                  v-else
                  type="text"
                  class="form-control"
                  v-model="profile.student_details.other_school_name"
                  placeholder="School Name"
                />
                <div
                  class="input-errors"
                  v-for="error of v$.profile.student_details.other_school_name
                    .$errors"
                  :key="error.$uid"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/DashboardService";
import CommonService from "@/services/CommonService";
import Multiselect from "@vueform/multiselect";
import useVuelidate from "@vuelidate/core";
import {
  required,
  requiredIf,
  email,
  numeric,
  maxLength,
  minLength,
  helpers,
} from "@vuelidate/validators";
import moment from "moment";

export default {
  name: "ProfileTab",
  components: {
    Multiselect,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      isFormActive: false,
      // TODO: use these boolean objects for Email & Phone updates
      isEmailUpdating: false,
      isPhoneUpdating: false,
      isOtherSchool: false,
      fullPage: true,
      // Profile data - structure based on the returned api response of fetchProfile()
      profile: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        gender: "",
        image: "",
        bio: "",
        timezone: "",
        student_details: {
          dob: "",
          address: "",
          state: "",
          zip_code: "",
          parent_name: "",
          parent_contact_number: "",
          advertising_medium: "",
          other_school_name: "",
          school: {
            school_name: "",
          },
          standard: {
            standard_title: "",
          },
        },
        country: {
          name: "",
        },
      },
      masks: {
        input: "YYYY-MM-DD",
      },
      // Select options
      genderOptions: {
        options: [
          { value: "MALE", label: "Male" },
          { value: "FEMALE", label: "Female" },
          { value: "OTHER", label: "Other" },
        ],
        placeholder: "Select Gender",
        canDeselect: false,
        // required: true,
      },
      countryOptions: {
        value: null,
        options: [],
        placeholder: "Select Country",
        canDeselect: false,
        // required: true,
        searchable: true,
      },
      timezoneOptions: {
        value: null,
        options: [],
        placeholder: "Select Timezone",
        canDeselect: false,
        // required: true,
        searchable: true,
      },
      schoolOptions: {
        value: null,
        options: [],
        placeholder: "Select School",
        canDeselect: false,
        // required: true,
        searchable: true,
      },
      standardOptions: {
        value: null,
        options: [],
        placeholder: "Select Standard",
        canDeselect: false,
        // required: true,
        searchable: true,
      },
    };
  },
  validations() {
    return {
      profile: {
        first_name: {
          required: helpers.withMessage("Please enter first name", required),
        },
        last_name: {
          required: helpers.withMessage("Please enter last name", required),
        },
        email: {
          requiredIfNoPhone: helpers.withMessage(
            "Please enter email",
            requiredIf(this.isEmailUpdating)
          ),
          email: helpers.withMessage("Please enter valid email", email),
        },
        phone: {
          requiredIfNoEmail: helpers.withMessage(
            "Please enter contact no.",
            requiredIf(this.isPhoneUpdating)
          ),
          numeric: helpers.withMessage(
            "Please enter a valid contact no.",
            numeric
          ),
          maxLength: helpers.withMessage(
            "Max of 15 digits allowed",
            maxLength(15)
          ),
          minLength: helpers.withMessage(
            "It should be atleast of 6 digits",
            minLength(6)
          ),
        },
        gender: {
          required: helpers.withMessage("Please select gender", required),
        },
        bio: {
          maxLength: helpers.withMessage(
            "Max of 280 characters allowed",
            maxLength(280)
          ),
        },
        student_details: {
          parent_contact_number: {
            numeric: helpers.withMessage(
              "Please enter a valid contact no.",
              numeric
            ),
            maxLength: helpers.withMessage(
              "Max of 15 digits allowed",
              maxLength(15)
            ),
            minLength: helpers.withMessage(
              "It should be atleast of 6 digits",
              minLength(6)
            ),
          },
          // FIXME: requiredIf validation is not working as expected
          other_school_name: {
            requiredIfOtherSchool: helpers.withMessage(
              "Please enter school name",
              requiredIf(this.isOtherSchool)
            ),
          },
        },
      },
      timezoneOptions: {
        value: {
          required: helpers.withMessage("Please select timezone", required),
        },
      },
      standardOptions: {
        value: {
          required: helpers.withMessage("Please select standard", required),
        },
      },
    };
  },
  mounted() {
    this.fetchProfile();
  },
  watch: {
    isFormActive(newVal) {
      if (newVal === true) {
        this.fetchAllSelectOptions();
      }
    },
  },
  computed: {
    studentName() {
      let fullName = "Student";
      let user = this.$store.state.auth.user;
      // console.log(user);
      // console.log(user.length);
      if (user.length != undefined) {
        user = JSON.parse(user);
        if (user.first_name !== null) {
          if (user.last_name !== null) {
            fullName = user.first_name + " " + user.last_name;
          } else {
            fullName = user.first_name;
          }
        }
      }
      return fullName;
    },
    minDate() {
      // Note:- toDate() is to get the Date() equivalent value from moment
      return moment().subtract(100, "years").toDate();
    },
    maxDate() {
      return moment().subtract(3, "years").toDate();
    },
  },
  methods: {
    async fetchProfile() {
      await DashboardService.getProfile()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.profile = response.data.profile;
            if (this.profile.student_details.other_school_name !== null) {
              this.isOtherSchool = true;
            }
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchAllSelectOptions() {
      this.activeSchools();
      this.activeStandards();
      this.allCountries();
      this.allTimezones();
    },
    checkIfOtherSchool() {
      this.profile.student_details.other_school_name = "";
      if (this.schoolOptions.value === 3) {
        // user id of the Other school = 3
        this.isOtherSchool = true;
      } else {
        this.isOtherSchool = false;
      }
    },
    async activeSchools() {
      await CommonService.getActiveSchools()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.schoolOptions.options = []; // to clear previously loaded options
            let schools = response.data.schools;
            if (schools.length > 0) {
              schools.map((school) => {
                let options = {
                  value: school.id,
                  label: school.school_name,
                };
                this.schoolOptions.options.push(options);
              });
              this.schoolOptions.value = this.profile.student_details.school_id;
            }
            // console.log(this.schoolOptions);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async activeStandards() {
      await CommonService.getActiveStandards()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.standardOptions.options = []; // to clear previously loaded options
            let standards = response.data.standards;
            if (standards.length > 0) {
              standards.map((standard) => {
                let options = {
                  value: standard.id,
                  label: standard.standard_title,
                };
                this.standardOptions.options.push(options);
              });
              this.standardOptions.value =
                this.profile.student_details.standard_id;
            }
            // console.log(this.standardOptions);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async allCountries() {
      await CommonService.getAllCountries()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.countryOptions.options = []; // to clear previously loaded options
            let countries = response.data.countries;
            if (countries.length > 0) {
              countries.map((country) => {
                let options = {
                  value: country.id,
                  label: country.country_name,
                };
                this.countryOptions.options.push(options);
              });
              this.countryOptions.value = this.profile.country_id;
            }
            // console.log(this.countryOptions);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async allTimezones() {
      await CommonService.getAllTimezones()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.timezoneOptions.options = []; // to clear previously loaded options
            let timezones = response.data.timezones;
            if (timezones.length > 0) {
              timezones.map((timezone) => {
                let options = {
                  value: timezone.timezone,
                  label: timezone.timezone,
                };
                this.timezoneOptions.options.push(options);
              });
              this.timezoneOptions.value = this.profile.timezone;
            }
            // console.log(this.timezoneOptions);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateProfile() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.$toast.error("Kindly enter correct details!");
        // console.log(this.v$.$errors);
        return;
      }
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.profileContainer,
      });

      let params = {
        first_name: this.profile.first_name,
        last_name: this.profile.last_name,
        bio: this.profile.bio,
        gender: this.profile.gender,
        timezone: this.timezoneOptions.value,
        country_id: this.countryOptions.value,
        parent_name: this.profile.student_details.parent_name,
        parent_contact_number:
          this.profile.student_details.parent_contact_number,
        state: this.profile.student_details.state,
        zip_code: this.profile.student_details.zip_code,
        address: this.profile.student_details.address,
        dob: this.profile.student_details.dob,
        advertising_medium: this.profile.student_details.advertising_medium,
        school_id: this.schoolOptions.value,
        school_name: this.profile.student_details.other_school_name,
        standard_id: this.standardOptions.value,
      };
      // console.log(params);
      DashboardService.updateProfile(params)
        .then((response) => {
          loader.hide();
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            const userInfo = {
              id: this.profile.id,
              first_name: params.first_name,
              last_name: params.last_name,
              email: this.profile.email,
              email_verified_at: this.profile.email_verified_at,
              phone: this.profile.phone,
              phone_verified_at: this.profile.phone_verified_at,
              timezone: params.timezone,
            };
            this.$toast.success(response.data.message);
            this.$store.dispatch("auth/updateUser", JSON.stringify(userInfo));
            this.fetchProfile();
            this.isFormActive = !this.isFormActive;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    revokeChanges() {
      this.isFormActive = !this.isFormActive;
      this.fetchProfile();
      this.v$.$reset(); // reset all validations
    },
    pickProfileImage() {
      this.$refs.imageUploader.click();
    },
    onImageSelected(event) {
      const selectedFile = event.target.files[0];
      console.log(selectedFile);
      if (
        selectedFile["type"] === "image/jpeg" ||
        selectedFile["type"] === "image/jpg" ||
        selectedFile["type"] === "image/png"
      ) {
        let formData = new FormData();
        formData.append("image", selectedFile);
        this.ProfileImageUpload(formData);
      } else {
        this.$toast.error("Please upload jpeg,jpg,png files only");
        this.$refs.imageUploader.value = null;
      }
    },
    ProfileImageUpload(params) {
      // console.log(params);
      DashboardService.updateProfileImage(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.$toast.success(response.data.message);
            this.$refs.imageUploader.value = null;
            if (response.data.image_url !== null) {
              this.profile.image = response.data.image_url;
            }
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
@import "@/style/profile-tab.scss";
</style>
